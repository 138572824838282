import React from 'react';
import * as c from 'constants/actions';
import createApiAction from 'api/createApiAction';
import { enqueueSnackbar } from './snackbar';
import { CURRENT_USER_LOCALSTORAGE_KEY } from 'constants/index';
import { admin } from 'constants/roles';
import { setAgentAvailable, setAgentBusy } from 'actions/agent';
import { FormattedMessage } from 'react-intl';

export const getPanicById = (params) =>
  createApiAction({
    method: 'getPanicById',
    params,
    requestAction: c.GET_PANIC_BY_ID_REQUEST,
    successAction: c.GET_PANIC_BY_ID_SUCCESS,
    errorAction: c.GET_PANIC_BY_ID_ERROR,
    transformResponse: (data) => {
      const currentUserString = localStorage.getItem(CURRENT_USER_LOCALSTORAGE_KEY);
      if (currentUserString) {
        try {
          const currentUser = JSON.parse(currentUserString);
          if (currentUser?.role === admin) {
            return data;
          }
          return data?.panic?.isTest === currentUser?.isTest ? data : null;
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('currentUser parse error', e);
        }
      }
      return data;
    },
  });

export const getPanicHistoryRequest = (params) =>
  createApiAction({
    method: 'getPanicHistory',
    params,
    requestAction: c.GET_PANIC_HISTORY_REQUEST,
    successAction: c.GET_PANIC_HISTORY_SUCCESS,
    errorAction: c.GET_PANIC_HISTORY_ERROR,
    transformResponse: ({ items: data }) => {
      const currentUserString = localStorage.getItem(CURRENT_USER_LOCALSTORAGE_KEY);
      if (currentUserString) {
        try {
          const currentUser = JSON.parse(currentUserString);
          if (currentUser?.role === admin) {
            return data;
          }
          return data.filter((panic) => panic?.panic?.isTest === currentUser?.isTest);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('currentUser parse error', e);
        }
      }
      return data;
    },
  });

export const getActivePanics = (params) =>
  createApiAction({
    method: 'getActivePanicHistory',
    params,
    requestAction: c.GET_ACTIVE_PANIC_HISTORY_REQUEST,
    successAction: c.GET_ACTIVE_PANIC_HISTORY_SUCCESS,
    errorAction: c.GET_ACTIVE_PANIC_HISTORY_ERROR,
    transformResponse: ({ items }) => items,
  });

export const getWaitingPanics = (params) =>
  createApiAction({
    method: 'getWaitingPanicHistory',
    params,
    requestAction: c.GET_WAITING_PANIC_HISTORY_REQUEST,
    successAction: c.GET_WAITING_PANIC_HISTORY_SUCCESS,
    errorAction: c.GET_WAITING_PANIC_HISTORY_ERROR,
    transformResponse: ({ items }) => items,
  });

export const assignAgentRequest = ({ params, cb = () => {} }) =>
  createApiAction({
    method: 'assignAgent',
    params,
    requestAction: c.ASSIGN_AGENT_REQUEST,
    requestPayload: params,
    successAction: c.ASSIGN_AGENT_SUCCESS,
    errorHandler(dispatch, { response }) {
      // temp solution for SHOULD_BE_PENDING, will be updated in the future in favour of api message traslantions.
      dispatch(
        enqueueSnackbar({
          message: response.data.message === 'SHOULD_BE_PENDING' ? <FormattedMessage id="backend_errors.SHOULD_BE_PENDING" /> : response.data.message,
          options: {
            variant: 'error',
            ...(response.data.message === 'SHOULD_BE_PENDING' ? {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            } : {})
          },
        })
      );
      dispatch({
        type: c.ASSIGN_AGENT_ERROR,
        payload: params,
      });
      cb();
    },
    afterSuccess: (dispatch) => {
      cb();
      dispatch(setAgentBusy(params.data.agentId))
    },
  });

export const deassignAgentRequest = ({ params, cb = () => {} }) => {
  const { id, data } = params;
  return createApiAction({
    method: 'deassignAgent',
    params: { id },
    requestAction: c.DEASSIGN_AGENT_REQUEST,
    successAction: c.DEASSIGN_AGENT_SUCCESS,
    errorAction: c.DEASSIGN_AGENT_ERROR,
    transformResponse: ({ data }) => data,
    afterSuccess: (dispatch) => {
      dispatch(getPanicById(params.id));
      dispatch(setAgentAvailable(data.agentId))
      cb();
    },
  })
};

export const rejectCancelationRequest = ({ id, cb }) =>
  createApiAction({
    method: 'rejectCancelation',
    params: id,
    requestAction: c.REJECT_PANIC_CANCEL_REQUEST,
    successAction: c.REJECT_PANIC_CANCEL_SUCCESS,
    errorAction: c.REJECT_PANIC_CANCEL_ERROR,
    transformResponse: () => ({ id }),
    afterSuccess: () => {
      cb();
    },
  });
export const cancelPanicRequest = ({ id, cb }) =>
  createApiAction({
    method: 'cancelPanic',
    params: id,
    requestAction: c.CANCEL_PANIC_REQUEST,
    successAction: c.CANCEL_PANIC_SUCCESS,
    errorAction: c.CANCEL_PANIC_ERROR,
    transformResponse: () => ({ id }),
    afterSuccess: () => {
      cb();
    },
  });

export const agentInPlaceRequest = (id) =>
  createApiAction({
    method: 'agentInPlace',
    params: id,
    requestAction: c.AGENT_IN_PLACE_REQUEST,
    successAction: c.AGENT_IN_PLACE_SUCCESS,
    errorAction: c.AGENT_IN_PLACE_ERROR,
    transformResponse: () => ({ id }),
    afterSuccess: (dispatch) => {
      dispatch(getPanicById(id));
    },
  });

export const panicDoneRequest = ({ id, cb }) =>
  createApiAction({
    method: 'panicDone',
    params: id,
    requestAction: c.PANIC_DONE_REQUEST,
    successAction: c.PANIC_DONE_SUCCESS,
    errorAction: c.PANIC_DONE_ERROR,
    transformResponse: () => ({ id }),
    afterSuccess: (dispatch) => {
      cb();
      dispatch(getPanicById(id));
    },
  });

export const togglePanicDetailsModal = ({ id = 0, show }) => ({
  type: c.TOGGLE_PANIC_DETAILS_MODAL,
  payload: { id, show },
});

// removes panic id from allIds
export const removePanicById = (id) => ({
  type: c.REMOVE_PANIC_BY_ID,
  payload: { id },
});

export const clearPanicRouteHistory = () => ({
  type: c.CLEAR_PANIC_ROUTE_HISTORY,
});

export const getPanicRouteHistory = (params) =>
  createApiAction({
    method: 'getPanicRouteHistory',
    params,
    requestAction: c.GET_PANIC_ROUTE_HISTORY_REQUEST,
    successAction: c.GET_PANIC_ROUTE_HISTORY_SUCCESS,
    errorAction: c.GET_PANIC_ROUTE_HISTORY_ERROR,
    transformResponse: ({ user, agent }) => {
      return {
        user: user.map(({ createdAt, location }) => {
          const [lat, lng] = location?.split(',') || [];
          return { createdAt, lat, lng }
        }),
        agent: agent.map(({ createdAt, location }) => {
          const [lat, lng] = location?.split(',') || [];
          return { createdAt, lat, lng }
        })
      }
    }
  });
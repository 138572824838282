import { createReducer } from '../utils';
import * as c from '../constants/actions';
import { PanicStatus } from 'types';

const initialRouteHistory = {
  user: [],
  agent: [],
};

const initialState = {
  details: {
    panic: {},
    agent: {},
    client: {},
  },
  byId: {},
  allIds: [],
  pendingAssignment: [],
  isFullModalOpen: false,
  selectedPanicId: 0,
  routeHistory: initialRouteHistory,
  isLoading: false,
  error: null,
};

const normalizePanics = (arr) => arr.reduce((map, item) => ({ ...map, [item.id]: item }), {});

export default createReducer(initialState, {
  [c.GET_PANIC_BY_ID_REQUEST]: (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_PANIC_BY_ID_SUCCESS]: (state, { payload }) => {
    if (!payload) {
      return { ...state };
    }
    return {
      ...state,

      // todo: check if details is used
      // details: {
      //   ...state.details,
      //   panic: payload
      // },
      // @TODO: need to check if this still causes an issue:
      // issue will occur when you get panic by id (usually after websocksocket trigger without getting the panic list first)
      byId: { ...state.byId, [payload.id]: payload },
      pendingAssignment: payload.status === PanicStatus.pending ? state.pendingAssignment : state.pendingAssignment.filter((id) => id !== payload.id),
      isLoading: false,
    };
  },
  [c.GET_PANIC_BY_ID_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_PANIC_HISTORY_REQUEST]: (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_PANIC_HISTORY_SUCCESS]: (state, { payload }) => ({
    ...state,
    byId: { ...state.byId, ...normalizePanics(payload) },
    allIds: payload.map((el) => el.id),
    isLoading: false,
  }),
  [c.GET_PANIC_HISTORY_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.GET_ACTIVE_PANIC_HISTORY_REQUEST]: (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }),
  [c.GET_ACTIVE_PANIC_HISTORY_SUCCESS]: (state, { payload }) => ({
    ...state,
    // byId: {
    //   // force set panics status canceled
    //   ...(!payload?.length
    //     ? normalizePanics(
    //       Object.values(state.byId).map((item) => {
    //         const newItem = { ...item };
    //         if (![PanicStatus.canceled, PanicStatus.done].includes(newItem.status)) {
    //           newItem.panic.status = PanicStatus.canceled;
    //         }
    //         return newItem;
    //       })
    //     )
    //     : state.byId),
    //   ...normalizePanics(payload),
    // },
    byId: { ...state.byId, ...normalizePanics(payload) },
    allIds: [...new Set([...payload.map((el) => el.id), ...state.allIds])],
    isLoading: false,
  }),

  [c.GET_WAITING_PANIC_HISTORY_SUCCESS]: (state, { payload }) => ({
    ...state,
    // byId: {
    //   // force set panics status canceled
    //   ...(!payload?.length
    //     ? normalizePanics(
    //       Object.values(state.byId).map((item) => {
    //         const newItem = { ...item };
    //         if (![PanicStatus.canceled, PanicStatus.done].includes(newItem.status)) {
    //           newItem.panic.status = PanicStatus.canceled;
    //         }
    //         return newItem;
    //       })
    //     )
    //     : state.byId),
    //   ...normalizePanics(payload),
    // },
    byId: { ...state.byId, ...normalizePanics(payload) },
    allIds: [...new Set([...payload.map((el) => el.id), ...state.allIds])],
    isLoading: false,
  }),
  [c.GET_ACTIVE_PANIC_HISTORY_ERROR]: (state, { payload }) => ({
    ...state,
    error: payload,
    isLoading: false,
  }),
  [c.CANCEL_PANIC_SUCCESS]: (state) =>
    // const byId = { ...state.byId };
    // delete byId[payload.id];
    ({
      ...state,
      // allIds: state.allIds.filter((id) => id !== payload.id),
      // byId,
    }),
  [c.PANIC_DONE_SUCCESS]: (state, { payload }) => {
    const byId = { ...state.byId };
    delete byId[payload.id];
    return {
      ...state,
      allIds: state.allIds.filter((id) => id !== payload.id),
      byId,
    };
  },
  [c.TOGGLE_PANIC_DETAILS_MODAL]: (state, { payload }) => ({
    ...state,
    selectedPanicId: payload.id,
    isFullModalOpen: payload.show,
  }),
  [c.REMOVE_PANIC_BY_ID]: (state, { payload }) => ({
    ...state,
    allIds: state.allIds.filter((id) => id !== payload.id),
    pendingAssignment: state.pendingAssignment.filter((id) => id !== payload.id),
  }),
  [c.ASSIGN_AGENT_REQUEST]: (state, { payload }) => ({
    ...state,
    pendingAssignment: [...state.pendingAssignment, payload.id],
  }),
  [c.ASSIGN_AGENT_ERROR]: (state, { payload }) => ({
    ...state,
    pendingAssignment: state.pendingAssignment.filter((id) => id !== payload.id),
  }),
  [c.GET_PANIC_ROUTE_HISTORY_SUCCESS]: (state, { payload }) => ({
    ...state,
    routeHistory: payload,
  }),
  [c.CLEAR_PANIC_ROUTE_HISTORY]: (state) => ({
    ...state,
    routeHistory: initialRouteHistory,
  }),
});

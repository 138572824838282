import React, { ComponentType, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LS_TOKEN_KEY } from '../constants';
import {
  getWaitingPanics,
  getPanicById,
  removePanicById,
  togglePanicDetailsModal,
} from 'actions/panic';
import { getAvailableAgentsRequest,  updateSingleAgentCoords } from 'actions/agent';
import { IAppState, IPanicObjectProps, PanicStatus } from '../types';
import { notificationTypes } from '../constants/colors';
import PanicNotification, { PanicNotificationActions } from './panic-notification';
import { sendLogToCrm } from '../utils/crm-utils';
import usePanicAudio from '../hook/usePanicAudio';
import { updateClientCoords } from 'actions/client';
import { PENDING_PANICS } from 'constants/panic';

const wsUrl = process.env.REACT_APP_WS_URL;

let panicIdsSentToAmoCrm: number[] = JSON.parse(localStorage.getItem('panicIdsSentToAmoCrm') ?? '[]') || [];


interface StateProps {
  tokenFromState: string;
}

type Props = StateProps;

const WebSocketClient = ({
  tokenFromState,
}:Props): null => {
  const displayedPanicsRef = useRef<Set<number>>(new Set());
  const token = tokenFromState || window.localStorage.getItem(LS_TOKEN_KEY);
  const tokenUrl = `${wsUrl}?token=${token}`;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const panicList = useSelector(({ panic: { allIds, byId } }: IAppState) => allIds.map((id) => byId[id]));
  const pendingAssignmentList = useSelector(({ panic: { pendingAssignment } }: IAppState) => pendingAssignment);
  const dispatch = useDispatch();

  const { playSound, stopSound } = usePanicAudio()

  const setPendingPanic = useCallback((pendingPanics: IPanicObjectProps[]) => {

    pendingPanics.forEach((pendingPanic) => {
      if (!displayedPanicsRef.current.has(pendingPanic.id)) {
        displayedPanicsRef.current.add(pendingPanic.id);
        playSound();
        enqueueSnackbar(
          <PanicNotification
            notifKey={`pending_panic_${pendingPanic.id}`}
            panicId={pendingPanic.id}
            status={pendingPanic.status}
          />,
          {
            key: `pending_panic_${pendingPanic.id}`,
            variant: notificationTypes[pendingPanic.status],
            action: (key) => (
              <PanicNotificationActions notifKey={key} withDetails id={pendingPanic.id} />
            ),
            style: { padding: '0px 10px' },
            persist: true,
            preventDuplicate: true,
          }
        );
      }
      if (!panicIdsSentToAmoCrm.includes(pendingPanic.id)) {
        panicIdsSentToAmoCrm = [...panicIdsSentToAmoCrm, pendingPanic.id];
        localStorage.setItem('panicIdsSentToAmoCrm', JSON.stringify(panicIdsSentToAmoCrm));
        sendLogToCrm({
          fields: {
            837855: pendingPanic.id,
            837859: pendingPanic.user.id,
            837861: 'ip not found',
            837865: 'operator',
          },
          contact: {
            // @TODO: INCLUDE THE PLAN OWNER PHONE HERE
            // @RE-TODO Added owner phone or empty string if inFamily has no elements
            phone: pendingPanic.user.inFamily.find((x) => x.user.plan.isActive)?.user.phone || '',
          },
        });
      }
    });
  }, []);

  useEffect(() => {
    const pendingPanics = panicList.filter(
      (panic: IPanicObjectProps) =>
        PENDING_PANICS.includes(panic.status) &&
        pendingAssignmentList.indexOf(panic.id) === -1
    );

    if (pendingPanics.length > 0) {
      setPendingPanic(pendingPanics);
    } else {
      stopSound();
    }
    /* TODO: need to update the dependecy to something else,
       currently it's triggering every time panic.byId is updated,
       even if it didn't change.
       A solution would be to keep track of pending panics in the store
       RE-TODO: added temp solution with JSON.stringify
    */
  }, [JSON.stringify(panicList), JSON.stringify(pendingAssignmentList)]);

  useEffect(() => {
    // todo maybe update to the socket.io
    const socket = new WebSocket(tokenUrl);

    socket.onmessage = ({ data }) => {
      const dataJSON = JSON.parse(data);
      if (!localStorage.getItem(LS_TOKEN_KEY)) {
        return;
      }
      // agent coords
      if (dataJSON.type === 'agent' && dataJSON.agentId) {
        const timeISO = moment().toISOString();
        dispatch(updateSingleAgentCoords({ ...dataJSON, timeISO, online: true }));
      }
      // in case 2 or more operators (in the same area) assign an agent in the first 15(approximate) seconds of the panic creation
      // the backend will decide which one is more suited and the rest will have the panic removed from the list
      //   {
      //     "to": "3",
      //     "type": "panic.status",
      //     "value": "race_lost",
      //     "id": 3623
      // }
      if (dataJSON.type === 'panic.status' && dataJSON.value === 'race_lost') {
        // remove panic from list since it's no longer available for the current operator
        dispatch(removePanicById(dataJSON.id));
        // close modal
        dispatch(togglePanicDetailsModal({ show: false }));
        // get agents availability,
        // note: using dispatch(setAgentAvailable()) is not posible due to missing agentId
        dispatch(getAvailableAgentsRequest());
        // close pending panic snackbar
        closeSnackbar(`pending_panic_${dataJSON.id}`);
        displayedPanicsRef.current.delete(dataJSON.id);
        // add snackbar
        enqueueSnackbar(<PanicNotification notifKey={dataJSON.id} panicId={dataJSON.id} status={dataJSON.value} />, {
          variant: notificationTypes[dataJSON.value as 'race_lost'],
          action: (key) => <PanicNotificationActions notifKey={key} id={dataJSON.id} />,
          style: { padding: '0px 10px' },
        });

      }


      // client coords
      // for example see import { ClientLiveCoords } from 'types'
      // note on ios the message somethimes doesnt include lat and lon
      if (dataJSON.type === 'client' && dataJSON.clientId && dataJSON.lat && dataJSON.lon) {
        dispatch(updateClientCoords(dataJSON));
      }

      // update panic on status change
      if (dataJSON.type === 'panic.status' && dataJSON.value !== 'race_lost') {
        dispatch(getPanicById(dataJSON.id));
        // get agents availability
        // note: using dispatch(setAgentAvailable() or setAgentBusy()) is not posible due to missing agentId
        dispatch(getAvailableAgentsRequest());

        if (PENDING_PANICS.includes(dataJSON.value)) {
          // note: might be redundant since we already getPanicById
          dispatch(getWaitingPanics({ offset: 50 }));
        } else {
          closeSnackbar(`pending_panic_${dataJSON.id}`);
          displayedPanicsRef.current.delete(dataJSON.id);
          enqueueSnackbar(<PanicNotification notifKey={dataJSON.id} panicId={dataJSON.id} status={dataJSON.value} />, {
            variant: notificationTypes[dataJSON?.value as PanicStatus],
            action: (key) => <PanicNotificationActions notifKey={key} id={dataJSON.id} />,
            style: { padding: '0px 10px' },
          });
        }
      }
    };

    return () => {
      try {
        socket.close();
      } catch (err) {
        global.console.log('Error in closing socket: ', err);
      }
    };
  }, []);

  return null;
};

const mapStateToProps = ({ auth }: IAppState): StateProps => ({
  tokenFromState: auth.onLoginToken,
});

export default compose<ComponentType>(withRouter, connect(mapStateToProps))(WebSocketClient);

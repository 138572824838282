import axios from 'axios';
import api from './methods';

/**
 * @method getEthereumMetadata
 */
class ApiClient {
  constructor(apiBase) {
    this.apiBase = apiBase;
    this.token = null;
  }

  /**
   * @param {string|null} token
   */
  setJwtToken(token) {
    this.token = token;
  }

  /**
   * @param {string} apiBase
   */
  setApiBase(apiBase) {
    this.apiBase = apiBase;
  }

  /**
   * Injects API methods from config
   * @param {*} metadata
   */
  injectApiMethods(metadata) {
    // eslint-disable-next-line
    for (const name of Object.keys(metadata)) {
      this[name] = async (...args) => {
        const { method, url, data, transform, custom, headers, options } = await metadata[name](...args);

        if (custom) {
          // eslint-disable-next-line
          return await custom(...args);
        }

        let result = await this.request(method, url, data, headers, options);

        if (transform && typeof transform === 'function') {
          result = await transform(result);
        }

        return result;
      };
    }

    return this;
  }

  /**
   * Make a request to API
   * @param {string} method
   * @param {string} url
   * @param {object} options
   * @param {object} headers
   * @param {*} data
   */
  async request(method, url, data = {}, reqHeaders = {}, options = {}) {
    const headers = {
      ...this.headers(),
      ...reqHeaders,
    };

    // @ref https://www.npmjs.com/package/axios
    const params = {
      // withCredentials: true,
      method,
      headers,
      url,
      baseURL: this.apiBase,
      [this.dataSendType(method)]: data,
      ...options,
    };

    const response = await axios.request(params);

    return response.data;
  }

  /**
   * Make a request to API
   * @param {string} method
   * @param {string} url
   * @param {object} options
   * @param {object} headers
   * @param {*} data
   */
  async requestRaw(method, url, data = {}, reqHeaders = {}, options = {}) {
    const headers = {
      ...this.headers(),
      ...reqHeaders,
    };

    // @ref https://www.npmjs.com/package/axios
    const params = {
      // withCredentials: true,
      method,
      headers,
      url,
      baseURL: this.apiBase,
      [this.dataSendType(method)]: data,
      ...options,
    };

    const response = await axios.request(params);

    return response;
  }

  /**
   * @param {string} method
   * @returns {string}
   */
  dataSendType(method) {
    return method === 'GET' ? 'params' : 'data';
  }

  /**
   * Build request headers
   */
  headers() {
    const headers = ApiClient.STATIC_HEADERS;

    if (this.token) {
      headers.Authorization = `bearer ${this.token}`;
    }

    return headers;
  }

  addErrorInterceptor(interceptorFunc) {
    axios.interceptors.response.use((r) => r, interceptorFunc);
  }

  /**
   * @param {*} args
   */
  static create(...args) {
    const client = new this(...args);

    return client.injectApiMethods(api);
  }

  /**
   * Static headers
   */
  static get STATIC_HEADERS() {
    return {
      'Content-Type': 'application/json',
    };
  }

  /**
   * @returns {string}
   */
  static get JWT_QUERY_TOKEN() {
    return 'jwtToken';
  }

  /**
   * @return {String}
   */
  static get ACTION_KEY() {
    return '__api_req__';
  }
}

export default ApiClient;

import React, { useState } from 'react';
import { Box, ListItem, Typography, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { lightGray, darkPaper, white } from 'constants/colors';
import GetAppIcon from '@material-ui/icons/GetApp';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import FullImageModal from 'components/modal/qr-modal/index';
import VideoModal from 'components/modal/video-modal';
import { useFile } from 'utils/get-file';
import { IPanicObjectProps } from 'types';
import { panicTypeToIntlKey } from 'constants/panic';
import VideocamIcon from '@material-ui/icons/Videocam';

const useStyles = makeStyles({
  cardStyles: {
    padding: '8px 16px',
    boxShadow: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
  },
  cardHeader: {
    color: lightGray,
    fontWeight: 'bold',
  },
  imageContainer: {
    margin: 0,
    position: 'relative',
    maxHeight: '170px',
    maxWidth: '300px',
    width: 'auto',
    height: '100%'
  },
  imgActionContainer: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  imgAction: {
    display: 'flex',
    width: '40px',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    background: darkPaper,
    color: white,
    border: 'none',
    cursor: 'pointer',
  },
  reportPhoto: {
    maxHeight: '170px',
    maxWidth: '300px',
    minWidth: '80px',
  },
});

type Props = {
  panicData: IPanicObjectProps
}

const AgentComment = ({ panicData }: Props) => {
  const { cardStyles, cardHeader, imageContainer, imgActionContainer, imgAction, reportPhoto } = useStyles();
  const [modalIsOpen, toggleModal] = useState(false);
  const [isVideoModalOpen, toggleVideoModal] = useState(false);

  const toggleImageModal = () => {
    toggleModal(!modalIsOpen);
  };

  const reportImage = useFile(
    panicData?.reportPhoto
      ? `v1/storage/file/protected/${panicData?.reportPhoto?.uuid}`
      : '',
    [panicData?.reportPhoto]
  );
  global.console.log('panicData?.reportVideo', panicData?.reportVideo);

  return (
    <Box className={cardStyles}>
      <Box display="flex" flexDirection="column" gridGap="8px" flex={1}>
        <Box>
          <Typography variant="body2" component="span" className={cardHeader}>
            <FormattedMessage id="common.agent_comments" />
            {': '}
          </Typography>
          <Typography variant="body2" component="span" style={{ whiteSpace: 'pre-wrap' }}>
            {panicData?.report ? panicData?.report.replaceAll('\\n', '\n') : '-'}
          </Typography>
        </Box>
        <Box display="flex" gridGap="8px" flex={1}>
          <Typography variant="body2" className={cardHeader}>
            <FormattedMessage id="panic_type.title" />
            {': '}
          </Typography>
          <Typography variant="body2">
            {panicData?.type ? <FormattedMessage id={panicTypeToIntlKey[panicData?.type]} /> : '-'}
          </Typography>
        </Box>
        {panicData?.reportVideo?.uuid && (
          <ListItem button onClick={() => toggleVideoModal(true)}>
            <Box display="flex" gridGap="8px" justifyContent="space-between" width="100%">
              <FormattedMessage id="common.video_report" />
              <VideocamIcon/>
            </Box>
          </ListItem>
        )}
      </Box>
      {reportImage && (
        <figure className={imageContainer}>
          <img src={reportImage} className={reportPhoto} alt="agent report" />
          <figcaption className={imgActionContainer}>
            <a className={imgAction} href={reportImage} download={`agent_report_panic_${panicData.id}`}>
              <GetAppIcon />
            </a>
            <button className={imgAction} onClick={() => toggleImageModal()} type="button">
              <ZoomOutMapIcon />
            </button>
          </figcaption>
        </figure>
      )}
      <FullImageModal fullWidth open={modalIsOpen} toggleModal={toggleImageModal} imgUrl={reportImage} />
      <VideoModal
        fullWidth
        open={isVideoModalOpen}
        toggleModal={toggleVideoModal}
        reportVideoId={panicData?.reportVideo?.uuid}
      />
    </Box>
  );
};

export default AgentComment;

import React from 'react';
import { makeStyles } from '@material-ui/core';
import DateUtils from '@date-io/moment'; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { gray } from '../../constants/colors';

const useStyles = makeStyles({
  container: {},
  input: {
    border: `1px solid ${gray}`,
    backgroundColor: 'transparent',
    fontWeight: 500,
    borderRadius: '0',
  },
});

type Props = {
  value: string | number | undefined;
  setFieldValue: (name: string, el?: any) => void;
  helperText?: string;
  name: string;
  label: any;
  minDate?: any;
  maxDate?: any;
  error?: boolean;
  required?: boolean;
};

const CustomDatePicker = ({ value, setFieldValue, helperText, name, label, minDate, maxDate, error, required }: Props) => {
  const { container, input } = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateUtils}>
      <DatePicker
        className={container}
        helperText={helperText}
        error={error}
        fullWidth
        value={value || null}
        format="dd/MM/yyyy"
        inputVariant="filled"
        label={label}
        margin="dense"
        InputLabelProps={{ shrink: true }}
        InputProps={{ disableUnderline: true, classes: { root: input } }}
        onChange={(date) => setFieldValue(name, date)}
        minDate={minDate || undefined}
        maxDate={maxDate || undefined}
        required={required}
      />
    </MuiPickersUtilsProvider>
  );
};

export default CustomDatePicker;

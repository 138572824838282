import { FormEvent } from 'react';


export enum MapType {
  GOOGLE = 'google',
  YANDEX = 'yandex',
}
export interface ProfileDataType {
  id: number;
  login: string;
  forgot?: null;
  push?: string | null;
  password?: string;
  role: 'client' | 'operator' | 'admin' | '';
  is_approved: number | boolean;
  phone: string;
  name: string;
  '2fa': number;
  '2fa_code': string;
  midname?: string | null;
  surname?: string | null;
  emergency_address?: string | null;
  emergency_contact?: string | null;
  emergency_contact2?: string | null;
  min_members?: number;
  // TODO fix any
  contract?: any;
  is_test?: boolean;
  providers: IProvider[];
  language: LocaleVariants;
}


export interface IAddressDetailsProps {
  apartment?: string | null;
  floor: number;
  id: number;
  intercomCode?: string | null;
  location: {
    latitude: number;
    longitude: number;
  }
  name: string;
  photos: string[] | null;
  region: string;
  streetAddress: string;
  streetNumber: string;
  userId: number;
}

export interface ICarDetailsProps {
  brand: string;
  color: string;
  id: number;
  license: string;
  model: string;
  photos: string[] | null;
  type: string;
  userId: number;
}

export interface PersonInfoType {
  birthdate: string;
  emergencyAddress: string | null;
  emergencyContact: string | null;
  firstName: string;
  height: number;
  id: number;
  lastName: string;
  midName: string;
  photo: IPhoto;
  photoId: number | null;
  secondEmergencyContact: string | null;
  userId: number;
  weight: number;
}

export interface IPhoto {
  uuid: string;
}

export interface PersonDetailsType {

  cars: ICarDetailsProps[];
  email: string;
  fullName: string;
  id: number;
  inFamily: IInFamily[];
  info: PersonInfoType;
  isTest: boolean;
  lastLocation: any;
  locationUpdatedAt: string | null;
  phone: string;
  photo: IPhoto;
  photoId: number;
  places: IAddressDetailsProps[];
  userProviders: IUserProvider[];


  // there are more fields in the actual data but not used atm


  // @TODO: Remove next fields
  // id: number;
  // name: string;
  // photo: string;
  // // phone: string;
  // user_id: number;
  // height: number;
  // weight: number;
  // status: string;
  // midname: string;
  // surname: string;
  // birthday: string;
  // is_busy?: boolean;
  // is_root: number;
  // car?: ICarDetailsProps;
  // created_at: string;
}

export interface IInFamily {
  id: number
  isRoot: boolean

  userId: number
  memberId: number

  user: IInFamilyUser
}

export interface IInFamilyUser {
  id: number

  fullName: string
  language: string
  phone: string

  plan: IPlan
}

export interface IPlan {
  id: number

  isTrial: boolean
  isSubscribed: boolean
  isActive: boolean
}

export interface IUserProvider {
  providerId: number;
  userId: number;
  role: string;
  isVideo?: boolean;
}

export interface FamilyDetailsType extends PersonDetailsType {
  address?: any;
  lastLocation: string;
}

export type AgentLocation = {
  timeISO: string | null,
  agentId: number;
  online: boolean;
  timeoutId: NodeJS.Timeout | null;
  to?: 'operator';
  type?: 'agent';
  lat?: string | number;
  lon?: string | number;
  eta?: number;
}

export interface IFamilyStoreType {
  allIds: number[];
  byId: FamilyDetailsType[];
  isSubmitting: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  availableIds: number[];
  error: Error | null;
  agentsLocation: Record<string, AgentLocation>;
}

export type RouteHistoryObject = { createdAt: string, lat: string, lng: string }

export interface IPanicStoreType {
  details: IPanicObjectProps;
  byId: { [p: number]: IPanicObjectProps };
  allIds: number[];
  pendingAssignment: number[];
  isFullModalOpen: boolean;
  selectedPanicId: number;
  isLoading: boolean;
  routeHistory: {
    user: RouteHistoryObject[],
    agent: RouteHistoryObject[],
  };
  error: Error | null;
}

interface ReportPhotoVideo {
  id: number,
  uuid: string,
  state: string,
  isPrivate: boolean,
  path: string,
  mimeType: string,
  name: string,
  size: number | null,
  otc: null,
  tenant: string,
  userId: number,
  createdAt: string,
  updatedAt?: string,
  deletedAt?: string
}
export interface IPanicObjectProps {
  id: number;
  userId: number;
  address: string;
  status: PanicStatus;
  comment: string;
  agentId: number;
  providerId: number;
  carId: number;
  userLat: number;
  userLon: number;
  agentName: string;
  agentLat: number;
  agentLon: number;
  agentEstimatedArrival: number;
  assignedAt: string;
  savedAt: string;
  closedAt: string;
  acceptedAt: string;
  inPlaceAt: string;
  extendedAt: string;
  report: string | null;
  reportPhotoId: number;
  reportPhoto: ReportPhotoVideo | null;
  reportVideo: ReportPhotoVideo | null;
  closedBy: string;
  isPayed: boolean;
  isTest: boolean;
  createdAt: string;
  updatedAt: string;
  user: PersonDetailsType;
  agent: PersonDetailsType;
  type: PanicType | null
}

export enum PanicType {
  LIFE_THREATENING = 'LIFE_THREATENING',
  SERVICE = 'SERVICE',
  FAKE = 'FAKE',
  TEST_SOS = 'TEST_SOS'
}


/**
 *
 * waiting (v2/panic/history/waiting)
 * ```
 * ["pending", "deassigned", "assigned"]
 * ```
 * active (v2/panic/history/active)
 * ```
 * ["accepted_operator", "accepted", "inplace", "processed", "extended"]
 * ```
 * ongoing
 * ```
 * ["done", "canceled"]
 * ```
 */
export enum PanicStatus {
  // waiting:
  pending = 'pending',
  deassigned = 'deassigned',
  assigned = 'assigned',
  cancel_request = 'cancel_request',
  // active:
  accepted_operator = 'accepted_operator',
  accepted = 'accepted',
  inplace = 'inplace',
  processed = 'processed',
  extended = 'extended',
  // ongoing:
  done = 'done',
  canceled = 'canceled',
}


export interface ISettingsStoreType {
  isGoogleMapEnabled: boolean;
  soundVolume: number;
}

export type ClientLiveCoords = {
  clientId: number,
  comment?: string,
  lat: number,
  lon: number,
  provider_id: number,
  to: string,
  type: string,
};

// note: we are renaming lon to lng as well
export type NormalizedClientLiveCoords = Record<number, { lat: number, lng: number }>;

export interface IClientCoordinatesStoreType {
  byId: NormalizedClientLiveCoords;
}
export interface IAuthStoreType {
  isAuthenticated: boolean;
  data: ProfileDataType;
  onLoginToken: string;
  isLoading: boolean;
  error: Error | null;
  show2FaModal: boolean;
  editLoading: boolean;
}

export interface IUserProviderModel {
  userId: number;
  role: 'client' | 'operator' | 'admin' | '';
}

export interface IProvider {
  id: number
  name: string
  type: string
  UserProviderModel: IUserProviderModel
}

export type LocaleVariants = 'ro' | 'ru' | 'en';

export interface ITranslationStoreType {
  locale: LocaleVariants;
}



export interface IAppState {
  auth: IAuthStoreType;
  client: IClientCoordinatesStoreType;
  family: IFamilyStoreType;
  panic: IPanicStoreType;
  translation: ITranslationStoreType;
  modal: IsModalStoreType;
  settings: ISettingsStoreType;
}

export interface IField {
  name: string;
  translationId: string;
  type: string;
  options?: string[];
  props?: { [key: string]: any };
}

export interface ICustomField {
  name?: string;
  value: any;
  onChange: (event: any) => void;
  onBlur?: (event: any) => void;
}

export interface ICustomForm {
  touched: any;
  errors: any;
  setFieldValue: (key: string, value: any) => void;
}

export interface IAuthPagesProps {
  login?: string;
  password?: string;
  loginRequest?: any;
  classes: any;
  values: any;
  touched: any;
  errors: any;
  handleChange: (event: FormEvent) => void;
  handleSubmit: (event: FormEvent) => void;
  auth: {
    isAuthenticated: boolean;
    isLoading: boolean;
    error: boolean;
  };
}




export interface IsModalStoreType {
  type: 'addAgent' | null;
  isOpen: boolean;
}

